var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"history"},[_c('div',[_c('el-row',{staticClass:"topRow"},[_c('el-col',{attrs:{"span":8}},[_c('el-date-picker',{attrs:{"type":"datetimerange","range-separator":"To","start-placeholder":"Start time","end-placeholder":"End Time","format":"yyyy-MM-dd HH:mm","value-format":"yyyy-MM-dd HH:mm","picker-options":"pickerOptions"},on:{"change":_vm.selectTimeFunc},model:{value:(_vm.timeRange),callback:function ($$v) {_vm.timeRange=$$v},expression:"timeRange"}})],1),_c('el-col',{attrs:{"span":2}},[_c('label',[_vm._v(_vm._s(_vm.$t('history.acc')))]),_c('el-switch',{model:{value:(_vm.accOn),callback:function ($$v) {_vm.accOn=$$v},expression:"accOn"}})],1),_c('el-col',{attrs:{"span":6}},[_c('el-button',{staticClass:"play",attrs:{"type":"primary","disabled":_vm.playDisable},on:{"click":_vm.playTrace}},[_vm._v(_vm._s(_vm.$t('history.play')))]),_c('el-button',{attrs:{"type":"success","disabled":_vm.pauseDisable},on:{"click":_vm.pauseTrace}},[_vm._v(_vm._s(_vm.pauseText))]),_c('el-button',{attrs:{"type":"info","disabled":_vm.stopDisable},on:{"click":_vm.stopTrace}},[_vm._v(_vm._s(_vm.$t('history.stop')))])],1),_c('el-col',{attrs:{"span":6}},[_c('el-col',{attrs:{"span":2}},[_c('span',{staticClass:"slow"},[_vm._v(_vm._s(_vm.$t('history.slow')))])]),_c('el-col',{attrs:{"span":4}},[_c('el-slider',{on:{"change":_vm.changeSlider},model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})],1),_c('el-col',{attrs:{"span":2}},[_c('span',{staticClass:"fast"},[_vm._v(_vm._s(_vm.$t('history.fast')))])])],1)],1)],1),(_vm.currentMap === 'google')?_c('GmapMap',{ref:"mapRef",staticClass:"mapClass",staticStyle:{"width":"100%","height":"95%"},attrs:{"map-type-id":"terrain","center":{lat:25.037798, lng:121.565170},"zoom":13,"options":{
        zoomControl: true,
        mapTypeControl: false,
        scaleControl: false,
        streetViewControl: false,
        rotateControl: false,
        fullscreenControl: true,
        disableDefaultUI: false
      }}}):_vm._e(),(_vm.currentMap === 'baidu')?_c('baidu-map',{staticClass:"baidu-map",attrs:{"center":_vm.center,"zoom":13,"scroll-wheel-zoom":true},on:{"ready":_vm.handleBaidu}},[_c('bm-navigation',{attrs:{"anchor":"BMAP_ANCHOR_TOP_RIGHT"}}),_c('bm-map-type',{attrs:{"map-types":['BMAP_NORMAL_MAP', 'BMAP_HYBRID_MAP'],"anchor":"BMAP_ANCHOR_TOP_LEFT"}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }